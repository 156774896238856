import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueResource from 'vue-resource';Vue.use(VueResource);
Vue.config.productionTip = false
Vue.use(VueResource);
// Vue.use(router);
// Transpiler for older browsers
import 'core-js/stable';
// import '@babel/polyfill';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime';


new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.documentElement.setAttribute('lang', 'en');
  }
}).$mount('#app')
